import styled from '@emotion/styled'
import React, { useEffect, useRef, useState } from 'react'
import Div100vh from 'react-div-100vh'

import PreviewPanel from './PreviewPanel'
import SelectorPanel from './SelectorPanel'
import './index.css'

const blockSVG = 'https://cdn.july.com/personalisation/personalisation/new/BLOCK.svg'
const boldSVG = 'https://cdn.july.com/personalisation/personalisation/new/BOLD.svg'
const bubbleSVG = 'https://cdn.july.com/personalisation/personalisation/new/BUBBLE.svg'
const graphicSVG = 'https://cdn.july.com/personalisation/personalisation/new/GRAPHIC.svg'
const heritageSVG = 'https://cdn.july.com/personalisation/personalisation/new/HERITAGE.svg'
const modernScriptSVG = 'https://cdn.july.com/personalisation/personalisation/new/MODERN-SCRIPT.svg'
const retroSerifSVG = 'https://cdn.july.com/personalisation/personalisation/new/RETRO-SERIF.svg'
const retroSVG = 'https://cdn.july.com/personalisation/personalisation/new/RETRO.svg'
const sansSerifSVG = 'https://cdn.july.com/personalisation/personalisation/new/SANS-SERIF.svg'
const serifSVG = 'https://cdn.july.com/personalisation/personalisation/new/SERIF.svg'
const signatureSVG = 'https://cdn.july.com/personalisation/personalisation/new/SIGNATURE.svg'

function objectExists(array, objToFind) {
  return array.some(obj => {
    // Check that every key in objToFind is in the obj from the array
    // and has the same value.
    return Object.keys(objToFind).every(
      key => obj.hasOwnProperty(key) && obj[key] === objToFind[key]
    );
  });
}



const splitter = (list) => {
  const segmenter = new Intl.Segmenter();
  return [...segmenter.segment(list)].map(val => val.segment);
}

const Emojireg =
  /\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff]([0-9]*)$/
const ChineseReg = /(\p{Script=Hani})+/gu

const textTransform = (text, font) => {
  if (
    [
      'Block',
      'Wide',
      'Casual',
      'all-serif',
      'all-sans',
      'Painted',
      'College',
    ].includes(font)
  ) {
    return text.toUpperCase()
  }
  return text
}
const colorNamesLuggage = [
  'Navy',
  'Charcoal',
  'Forest',
  'Nude',
  'Burgundy',
  'Monochrome',
  'Clay',
  'Sky',
  'Shadow',
]
const colorNamesBags = ['Charcoal', 'Forest', 'Chalk', 'Navy']
const colorNamesBagsPlus = ['Charcoal', 'Chalk', 'Navy']
const colorNamesPro = ['Navy', 'Charcoal', 'Forest', 'Monochrome']
const colorNamesPhone = ['Black', 'Navy', 'Forest', 'Taupe']
const colorNamesEverydayTotes = ['Natural', 'Camel', 'Sage', 'Navy', 'Black', 'Lilac', 'Copper']
const classicExpandable = ['Charcoal', 'Forest', 'Moss']


const textColorsLuggage = {
  'Lavender': '#90759B',
  'Lilac': '#DCC5DF',
  'Ballet-Pink': '#F1C9DE',
  'Rose-Pink': '#DC75A4',
  'Grenaine': '#9E5360',
  'Blush-Pink': '#E77472',
  'Magenta': '#B12D52',
  'Maroon': '#731A29',
  'Red': '#C92327',
  'Burnt-Orange': '#DC5527',
  'Mango': '#EF7E2E',
  'Rust': '#9B4827',
  'Toffee': '#705037',
  'Taupe': '#D3A289',
  'Butter-Yellow': '#FBF3BE',
  'Yellow': '#E8B629',
  'Lime': '#C6B937',
  'Green': '#4A813C',
  'Forest-Green': '#034123',
  'Mint': '#78AB93',
  'Ice-Blue': '#BEE3E9',
  'Denim': '#75A6B3',
  'Ocean-Blue': '#3E8EA1',
  'Blue': '#1466B2',
  'Grey': '#95979E',
  'Slate-Grey': '#63727E',
  'Black': '#000000',
  White: '#FFFFFF',
}

const textColorsBags = {
  Coral: '#FDC0B8',
  'Dusty-Pink': '#F5DFE2',
  Fuschia: '#DF3C7F',
  Mint: '#D4EECC',
  'Sky-Blue': '#BBE0EE',
  'Aqua-Blue': '#47BCEE',
  'French-Navy': '#182D6D',
  Red: '#C63531',
  Plum: '#AC3856',
  Purple: '#64258E',
  Lavender: '#957EA3',
  'Forest-Green': '#1C4125',
  Green: '#6A9A52',
  Yellow: '#E8BE47',
  Orange: '#E96737',
  White: '#FFFFFF',
  Grey: '#97999D',
  Black: '#000',
}

const ContentContanier = styled.div`
  height: 100%;
  position: relative;
  background: #f8f8f8;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  overflow: hidden;
  z-index: 999999999999;

  @media (min-width: 801px) {
    flex-direction: row-reverse;
  }
`
const TextBottomMargin = styled.div`
  @media (min-width: 801px) {
    margin-bottom: 109px;
  }
`

let defaultDisplaySize = {
  height: 25,
  width: 200,
  padding: {
    top: 4.1,
    bottom: 4.1,
    left: 7,
    right: 7,
  },
}

let defaultPreviewSize = {
  height: 80,
  width: 640,
  padding: {
    top: 13.3,
    bottom: 13.3,
    left: 18,
    right: 18,
  },
}

const phoneBackDisplaySize = {
  height: 200,
  width: 130,
  padding: {
    top: 4.1,
    bottom: 4.1,
    left: 7,
    right: 7,
  },
}

const phoneBackPreviewSize = {
  height: 80,
  width: 320,
  padding: {
    top: 13.3,
    bottom: 13.3,
    left: 18,
    right: 18,
  },
}

const phoneSideDisplaySize = {
  height: 110,
  width: 215,
  padding: {
    top: 4.1,
    bottom: 4.1,
    left: 12,
    right: 12,
  },
}

const phoneSidePreviewSize = {
  height: 100,
  width: 340,
  padding: {
    top: 13.3,
    bottom: 13.3,
    left: 18,
    right: 18,
  },
}

const tagBackDisplaySize = {
  height: 70,
  width: 120,
  padding: {
    top: 4.1,
    bottom: 4.1,
    left: 7,
    right: 7,
  },
}

const keyRingsBackDisplaySize = {
  height: 70,
  width: 120,
  padding: {
    top: 14.1,
    bottom: 14.1,
    left: 7,
    right: 7,
  },
}
const keyRingsBackPreviewSize = {
  height: 80,
  width: 320,
  padding: {
    top: 13.3,
    bottom: 13.3,
    left: 18,
    right: 18,
  },
}
const bowTagBackDisplaySize = {
  height: 70,
  width: 120,
  padding: {
    top: 14.1,
    bottom: 14.1,
    left: 7,
    right: 7,
  },
}
const bowTagBackPreviewSize = {
  height: 80,
  width: 320,
  padding: {
    top: 13.3,
    bottom: 13.3,
    left: 18,
    right: 18,
  },
}

const laptopTagBackDisplaySize = {
  height: 40,
  width: 120,
  padding: {
    top: 24.1,
    bottom: 24.1,
    left: 7,
    right: 7,
  },
}
const laptopTagBackPreviewSize = {
  height: 40,
  width: 320,
  padding: {
    top: 25,
    bottom: 25,
    left: 30,
    right: 30,
  },
}

const tagBackPreviewSize = {
  height: 80,
  width: 320,
  padding: {
    top: 13.3,
    bottom: 13.3,
    left: 18,
    right: 18,
  },
}

const passportBackDisplaySize = {
  height: 50,
  width: 250,
  padding: {
    top: 4.1,
    bottom: 4.1,
    left: 7,
    right: 7,
  },
}

const passportBackPreviewSize = {
  height: 80,
  width: 320,
  padding: {
    top: 13.3,
    bottom: 13.3,
    left: 18,
    right: 18,
  },
}

const walletBackDisplaySize = {
  height: 40,
  width: 120,
  padding: {
    top: 5.1,
    bottom: 5.1,
    left: 7,
    right: 7,
  },
}

const walletBackPreviewSize = {
  height: 80,
  width: 320,
  padding: {
    top: 13.3,
    bottom: 13.3,
    left: 18,
    right: 18,
  },
}

const tagSideDisplaySize = {
  height: 110,
  width: 215,
  padding: {
    top: 8,
    bottom: 8,
    left: 24,
    right: 24,
  },
}

const tagSidePreviewSize = {
  height: 100,
  width: 340,
  padding: {
    top: 13.3,
    bottom: 13.3,
    left: 18,
    right: 18,
  },
}

const fontData = {
  Block: {
    name: 'Block',
    uppercase: true,
    numbers: false,
    letterLimit: 5,
    displayImage: blockSVG,
  },
  Retro: {
    name: 'Retro',
    uppercase: false,
    numbers: true,
    letterLimit: 10,
    displayImage: retroSVG,
  },
  Serif: {
    name: 'Serif',
    uppercase: false,
    numbers: true,
    letterLimit: 10,
    displayImage: serifSVG,
  },
  Cursive: {
    name: 'Cursive',
    uppercase: false,
    numbers: true,
    letterLimit: 10,
    displayImage: modernScriptSVG,
  },
  Signature: {
    name: 'Signature',
    uppercase: false,
    numbers: true,
    letterLimit: 20,
    displayImage: signatureSVG,
  },
  'all-sans': {
    name: 'Carry All Sans',
    uppercase: true,
    numbers: false,
    letterLimit: 5,
    displayImage: sansSerifSVG,
  },
  'all-serif': {
    name: 'Carry All Serif',
    uppercase: true,
    numbers: false,
    letterLimit: 5,
    displayImage: serifSVG,
  },

  'graphic': {
    name: 'Graphic',
    uppercase: false,
    numbers: false,
    letterLimit: 10,
    displayImage: graphicSVG
  },
  'heritage': {
    name: 'Heritage',
    uppercase: false,
    numbers: false,
    letterLimit: 10,
    displayImage: heritageSVG
  },
  'bold-new': {
    name: 'Bold',
    uppercase: false,
    numbers: false,
    letterLimit: 5,
    displayImage: boldSVG
  },
  'retro-serif': {
    name: 'Retro Serif',
    uppercase: false,
    numbers: false,
    letterLimit: 10,
    displayImage: retroSerifSVG
  },
  'decorative': {
    name: 'Decorative',
    uppercase: false,
    numbers: false,
    letterLimit: 10,
    displayImage: modernScriptSVG
  },
  'sans-serif': {
    name: 'Sans Serif',
    uppercase: false,
    numbers: false,
    letterLimit: 10,
    displayImage: sansSerifSVG
  },
  'bubble': {
    name: 'Bubble',
    uppercase: false,
    numbers: false,
    letterLimit: 10,
    displayImage: bubbleSVG
  },
  'sans-serif-embos': {
    name: 'sans-serif-embos',
    uppercase: false,
    numbers: false,
    letterLimit: 10,
    displayImage: sansSerifSVG
  },
  'serif-embos': {
    name: 'serif-embos',
    uppercase: false,
    numbers: true,
    letterLimit: 10,
    displayImage: serifSVG,
  },

}

export const CaseData = {
  Carry: {
    imageName: 'carry-new',
    fonts: [],
    fontColors: textColorsLuggage,
    colors: ['1', '2', '3', '4', '5', '6', '7', '8'],
    colorNames: colorNamesLuggage,
    isLuggage: true,
    isMe: false,
    isBag: false,
    isPhone: false,
    chineseCapable: true,
    caseTypeName: 'case',
    defaultValues: {
      font: 'heritage',
      text: 'Mine',
      color: 'Green',
      shadow: 'Mint',
      location: 'Back',
    },
    locations: {
      Top: {
        top: '11.2%',
        bottom: 'auto',
        left: '0',
        right: '0',
        transform: 'rotate(0)',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
      Back: {
        top: '25%',
        bottom: 'auto',
        left: '0',
        right: '0',
        transform: 'rotate(0)',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
      Side: {
        top: 'auto',
        bottom: '45%',
        left: '-24.55%',
        right: '0%',
        transform: 'rotate(-90deg)',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
    },
  },
  CarryEssential: {
    imageName: 'carry-essential-new',
    fonts: [],
    fontColors: textColorsLuggage,
    colors: ['1', '2', '3', '4', '5', '6', '7', '8'],
    colorNames: colorNamesLuggage,
    isLuggage: true,
    isMe: false,
    isBag: false,
    isPhone: false,
    chineseCapable: true,
    caseTypeName: 'case',
    defaultValues: {
      font: 'heritage',
      text: 'Mine',
      color: 'Green',
      shadow: 'Mint',
      location: 'Back',
    },
    locations: {
      Top: {
        top: '11.2%',
        bottom: 'auto',
        left: '0',
        right: '0',
        transform: 'rotate(0)',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
      Back: {
        top: '27%',
        bottom: 'auto',
        left: '0',
        right: '0',
        transform: 'rotate(0)',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
      Side: {
        top: 'auto',
        bottom: '45%',
        left: '-19.55%',
        right: '0%',
        transform: 'rotate(-90deg)',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
    },
  },
  CarryUpside: {
    imageName: 'carry-new',
    fonts: [],
    fontColors: textColorsLuggage,
    colors: ['1', '2', '3', '4', '5', '6', '7', '8'],
    colorNames: colorNamesLuggage,
    isLuggage: true,
    isMe: false,
    isBag: false,
    isPhone: false,
    chineseCapable: true,
    caseTypeName: 'case',
    defaultValues: {
      font: 'heritage',
      text: 'Mine',
      color: 'Green',
      shadow: 'Mint',
      location: 'Back',
    },
    locations: {
      Back: {
        top: '75%',
        bottom: 'auto',
        left: '0',
        right: '0',
        transform: 'rotate(0)',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
      Side: {
        top: 'auto',
        bottom: '20%',
        left: '-19.55%',
        right: '0%',
        transform: 'rotate(-90deg)',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
    },
  },

  Checked: {
    imageName: 'checked-new',
    fonts: [],
    fontColors: textColorsLuggage,
    colors: ['1', '2', '3', '4', '5', '6', '7', '8'],
    colorNames: colorNamesLuggage,
    isLuggage: true,
    isMe: false,
    isBag: false,
    isPhone: false,
    chineseCapable: true,
    caseTypeName: 'case',
    defaultValues: {
      font: 'heritage',
      text: 'Mine',
      color: 'Green',
      shadow: 'Mint',
      location: 'Back',
    },
    locations: {
      Top: {
        top: '15.2%',
        bottom: 'auto',
        left: '0',
        right: '0',
        transform: 'rotate(0)',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
      Back: {
        top: '27%',
        bottom: 'auto',
        left: '0',
        right: '0',
        transform: 'rotate(0)',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
      Side: {
        top: 'auto',
        bottom: '41%',
        left: '-26.55%',
        right: '0%',
        transform: 'rotate(-90deg)',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
    },
  },
  CheckedExpandable: {
    imageName: 'checked-expandable',
    fonts: [],
    fontColors: textColorsLuggage,
    colors: ['1', '2', '3'],
    colorNames: classicExpandable,
    isLuggage: true,
    isMe: false,
    isBag: false,
    isPhone: false,
    chineseCapable: true,
    caseTypeName: 'case',
    defaultValues: {
      font: 'heritage',
      text: 'Mine',
      color: 'Green',
      shadow: 'Mint',
      location: 'Back',
    },
    locations: {
      Top: {
        top: '15.2%',
        bottom: 'auto',
        left: '0',
        right: '0',
        transform: 'rotate(0)',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
      Back: {
        top: '27%',
        bottom: 'auto',
        left: '0',
        right: '0',
        transform: 'rotate(0)',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
      Side: {
        top: 'auto',
        bottom: '41%',
        left: '-26.55%',
        right: '0%',
        transform: 'rotate(-90deg)',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
    },
  },
  Plus: {
    imageName: 'plus-new',
    fonts: [],
    fontColors: textColorsLuggage,
    colors: ['1', '2', '3', '4', '5', '6', '7', '8'],
    colorNames: colorNamesLuggage,
    isLuggage: true,
    isMe: false,
    isBag: false,
    isPhone: false,
    chineseCapable: true,
    caseTypeName: 'case',
    defaultValues: {
      font: 'heritage',
      text: 'Mine',
      color: 'Green',
      shadow: 'Mint',
      location: 'Back',
    },
    locations: {
      Top: {
        top: '16.2%',
        bottom: 'auto',
        left: '0',
        right: '0',
        transform: 'rotate(0)',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
      Back: {
        top: '27%',
        bottom: 'auto',
        left: '0',
        right: '0',
        transform: 'rotate(0)',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
      Side: {
        top: 'auto',
        bottom: '32%',
        left: '-29.55%',
        right: '0%',
        transform: 'rotate(-90deg)',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
    },
  },
  PlusExpandable: {
    imageName: 'plus-expandable',
    fonts: [],
    fontColors: textColorsLuggage,
    colors: ['1', '2', '3'],
    colorNames: classicExpandable,
    isLuggage: true,
    isMe: false,
    isBag: false,
    isPhone: false,
    chineseCapable: true,
    caseTypeName: 'case',
    defaultValues: {
      font: 'heritage',
      text: 'Mine',
      color: 'Green',
      shadow: 'Mint',
      location: 'Back',
    },
    locations: {
      Top: {
        top: '16.2%',
        bottom: 'auto',
        left: '0',
        right: '0',
        transform: 'rotate(0)',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
      Back: {
        top: '27%',
        bottom: 'auto',
        left: '0',
        right: '0',
        transform: 'rotate(0)',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
      Side: {
        top: 'auto',
        bottom: '32%',
        left: '-29.55%',
        right: '0%',
        transform: 'rotate(-90deg)',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
    },
  },
  Pro: {
    imageName: 'Pro',
    fonts: [],
    fontColors: textColorsLuggage,
    colors: ['1', '2', '3', '4'],
    colorNames: colorNamesLuggage,
    isLuggage: true,
    isMe: false,
    isBag: false,
    isPhone: false,
    chineseCapable: true,
    caseTypeName: 'case',
    defaultValues: {
      font: 'heritage',
      text: 'Mine',
      color: 'Green',
      shadow: 'Mint',
      location: 'Back',
    },
    locations: {
      Top: {
        top: '11.8%',
        bottom: 'auto',
        left: '0',
        right: '0',
        transform: 'rotate(0)',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
      Back: {
        top: '73%',
        bottom: 'auto',
        left: '0',
        right: '0',
        transform: 'rotate(0)',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
      Side: {
        top: 'auto',
        bottom: '19%',
        left: '-19.55%',
        right: '0%',
        transform: 'rotate(-90deg)',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
    },
  },
  'pro-v2': {
    imageName: 'pro-v2',
    fonts: [],
    fontColors: textColorsLuggage,
    colors: ['1', '2', '3', '4'],
    colorNames: colorNamesLuggage,
    isLuggage: true,
    isMe: false,
    isBag: false,
    isPhone: false,
    chineseCapable: true,
    caseTypeName: 'case',
    defaultValues: {
      font: 'heritage',
      text: 'Mine',
      color: 'Green',
      shadow: 'Mint',
      location: 'Back',
    },
    locations: {
      Top: {
        top: '13.8%',
        bottom: 'auto',
        left: '0',
        right: '0',
        transform: 'rotate(0)',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
      Back: {
        top: '29%',
        bottom: 'auto',
        left: '0',
        right: '0',
        transform: 'rotate(0)',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
      Side: {
        top: 'auto',
        bottom: '43%',
        left: '-19.55%',
        right: '0%',
        transform: 'rotate(-90deg)',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
    },
  },
  Light: {
    imageName: 'light-new',
    fonts: [],
    fontColors: textColorsLuggage,
    colors: ['1', '2', '3', '4', '5', '6', '7', '8'],
    colorNames: colorNamesLuggage,
    isLuggage: true,
    isMe: false,
    isBag: false,
    isPhone: false,
    chineseCapable: true,
    caseTypeName: 'case',
    defaultValues: {
      font: 'heritage',
      text: 'Mine',
      color: 'Green',
      shadow: 'Mint',
      location: 'Back',
    },
    locations: {
      Top: {
        top: '15%',
        bottom: 'auto',
        left: '0',
        right: '0',
        transform: 'rotate(0)',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
      Back: {
        top: '25%',
        bottom: 'auto',
        left: '0',
        right: '0',
        transform: 'rotate(0)',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
      Side: {
        top: 'auto',
        bottom: '50%',
        left: '-28.55%',
        right: '0%',
        transform: 'rotate(-90deg)',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
    },
  },
  max: {
    imageName: 'max',
    fonts: [],
    fontColors: textColorsLuggage,
    colors: ['1', '2', '3', '4', '5', '6'],
    colorNames: colorNamesLuggage,
    isLuggage: true,
    isMe: false,
    isBag: false,
    isPhone: false,
    chineseCapable: true,
    caseTypeName: 'case',
    defaultValues: {
      font: 'heritage',
      text: 'Mine',
      color: 'Green',
      shadow: 'Mint',
      location: 'Back',
    },
    locations: {
      Top: {
        top: '13%',
        bottom: 'auto',
        left: '0',
        right: '0',
        transform: 'rotate(0)',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
      Back: {
        top: '28%',
        bottom: 'auto',
        left: '0',
        right: '0',
        transform: 'rotate(0)',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
      Side: {
        top: 'auto',
        bottom: '50%',
        left: '-28.55%',
        right: '0%',
        transform: 'rotate(-90deg)',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
    },
  },
  LightCheckedPlus: {
    imageName: 'light-checked-plus',
    fonts: [],
    fontColors: textColorsLuggage,
    colors: ['1', '2', '3', '4', '5', '6', '7', '8'],
    colorNames: colorNamesLuggage,
    isLuggage: true,
    isMe: false,
    isBag: false,
    isPhone: false,
    chineseCapable: true,
    caseTypeName: 'case',
    defaultValues: {
      font: 'heritage',
      text: 'Mine',
      color: 'Green',
      shadow: 'Mint',
      location: 'Back',
    },
    locations: {
      Top: {
        top: '21%',
        bottom: 'auto',
        left: '0',
        right: '0',
        transform: 'rotate(0)',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
      Back: {
        top: '25%',
        bottom: 'auto',
        left: '0',
        right: '0',
        transform: 'rotate(0)',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
      Side: {
        top: 'auto',
        bottom: '28%',
        left: '-22.55%',
        right: '0%',
        transform: 'rotate(-90deg)',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
    },
  },
  LightChecked: {
    imageName: 'light-checked',
    fonts: [],
    fontColors: textColorsLuggage,
    colors: ['1', '2', '3', '4', '5', '6', '7', '8'],
    colorNames: colorNamesLuggage,
    isLuggage: true,
    isMe: false,
    isBag: false,
    isPhone: false,
    chineseCapable: true,
    caseTypeName: 'case',
    defaultValues: {
      font: 'heritage',
      text: 'Mine',
      color: 'Green',
      shadow: 'Mint',
      location: 'Back',
    },
    locations: {
      Top: {
        top: '21%',
        bottom: 'auto',
        left: '0',
        right: '0',
        transform: 'rotate(0)',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
      Back: {
        top: '25%',
        bottom: 'auto',
        left: '0',
        right: '0',
        transform: 'rotate(0)',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
      Side: {
        top: 'auto',
        bottom: '38%',
        left: '-22.55%',
        right: '0%',
        transform: 'rotate(-90deg)',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
    },
  },
  LightExpandable: {
    imageName: 'light-expandable-new',
    fonts: [],
    fontColors: textColorsLuggage,
    colors: ['1', '2', '3', '4', '5', '6', '7', '8'],
    colorNames: colorNamesLuggage,
    isLuggage: true,
    isMe: false,
    isBag: false,
    isPhone: false,
    chineseCapable: true,
    caseTypeName: 'case',
    defaultValues: {
      font: 'heritage',
      text: 'Mine',
      color: 'Green',
      shadow: 'Mint',
      location: 'Back',
    },
    locations: {
      Top: {
        top: '15%',
        bottom: 'auto',
        left: '0',
        right: '0',
        transform: 'rotate(0)',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
      Back: {
        top: '25%',
        bottom: 'auto',
        left: '0',
        right: '0',
        transform: 'rotate(0)',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
      Side: {
        top: 'auto',
        bottom: '55%',
        left: '28%',
        right: '0%',
        transform: 'rotate(-90deg)',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
    },
  },
  Tote: {
    imageName: 'Tote',
    fonts: [],
    fontColors: textColorsBags,
    colors: ['1', '2', '3', '4'],
    colorNames: colorNamesBags,
    isLuggage: false,
    isMe: false,
    isBag: true,
    isPhone: false,
    chineseCapable: true,
    caseTypeName: 'bag',
    defaultValues: {
      font: 'all-sans',
      text: 'Mine',
      color: 'Green',
      shadow: 'Mint',
      location: 'Back',
    },
    locations: {
      Back: {
        top: '68%',
        bottom: 'auto',
        left: '0',
        right: '0',
        transform: 'rotate(0)',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
    },
  },
  Weekender: {
    imageName: 'Weekender',
    fonts: [],
    fontColors: textColorsBags,
    colors: ['1', '2', '3', '4'],
    colorNames: colorNamesBags,
    isLuggage: false,
    isMe: false,
    isBag: true,
    isPhone: false,
    chineseCapable: true,
    caseTypeName: 'bag',
    defaultValues: {
      font: 'all-sans',
      text: 'Mine',
      color: 'Green',
      shadow: 'Mint',
      location: 'Back',
    },
    locations: {
      Back: {
        top: '61.5%',
        bottom: 'auto',
        left: '0',
        right: '3%',
        transform: 'rotate(0)',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
    },
  },
  'weekender-plus': {
    imageName: 'weekender-plus',
    fonts: [],
    fontColors: textColorsBags,
    colors: ['1', '2', '3'],
    colorNames: colorNamesBagsPlus,
    isLuggage: false,
    isMe: false,
    isBag: true,
    isPhone: false,
    chineseCapable: true,
    caseTypeName: 'bag',
    defaultValues: {
      font: 'all-sans',
      text: 'Mine',
      color: 'Green',
      shadow: 'Mint',
      location: 'Back',
    },
    locations: {
      Back: {
        top: '68.5%',
        bottom: 'auto',
        left: '0',
        right: '0%',
        transform: 'rotate(0)',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
    },
  },
  Backpack: {
    imageName: 'Backpack',
    fonts: [],
    fontColors: textColorsBags,
    colors: ['1', '2', '3', '4'],
    colorNames: colorNamesBags,
    isLuggage: false,
    isMe: false,
    isBag: true,
    isPhone: false,
    chineseCapable: true,
    caseTypeName: 'bag',
    defaultValues: {
      font: 'all-sans',
      text: 'Mine',
      color: 'Green',
      shadow: 'Mint',
      location: 'Back',
    },
    locations: {
      Back: {
        top: '60.5%',
        bottom: 'auto',
        left: '1.5%',
        right: '0',
        transform: 'rotate(0)',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
    },
  },
  'daybreak-backpack': {
    imageName: 'daybreak-backpack',
    fonts: [],
    fontColors: textColorsBags,
    colors: ['1', '2', '3', '4'],
    colorNames: colorNamesBags,
    isLuggage: false,
    isMe: false,
    isBag: true,
    isPhone: false,
    chineseCapable: true,
    caseTypeName: 'bag',
    defaultValues: {
      font: 'all-sans',
      text: 'Mine',
      color: 'Green',
      shadow: 'Mint',
      location: 'Back',
    },
    locations: {
      Back: {
        top: '51.5%',
        bottom: 'auto',
        left: '0',
        right: '0',
        transform: 'rotate(0)',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
    },
  },
  'eve-duf-small': {
    imageName: 'eve-duf-small',
    fonts: [],
    fontColors: textColorsBags,
    colors: ['1', '2', '3', '4', '5', '6', '7'],
    colorNames: colorNamesEverydayTotes,
    isLuggage: false,
    isMe: false,
    isBag: true,
    isPhone: false,
    chineseCapable: true,
    caseTypeName: 'bag',
    defaultValues: {
      font: 'all-sans',
      text: 'Mine',
      color: 'Green',
      shadow: 'Mint',
      location: 'Back',
    },
    locations: {
      Back: {
        top: '54.3%',
        bottom: 'auto',
        left: '0',
        right: '2%',
        transform: 'rotate(0)',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
    },
  },
  'eve-duf-large': {
    imageName: 'eve-duf-small',
    fonts: [],
    fontColors: textColorsBags,
    colors: ['1', '2', '3', '4', '5', '6', '7'],
    colorNames: colorNamesEverydayTotes,
    isLuggage: false,
    isMe: false,
    isBag: true,
    isPhone: false,
    chineseCapable: true,
    caseTypeName: 'bag',
    defaultValues: {
      font: 'all-sans',
      text: 'Mine',
      color: 'Green',
      shadow: 'Mint',
      location: 'Back',
    },
    locations: {
      Back: {
        top: '55.3%',
        bottom: 'auto',
        left: '0',
        right: '2%',
        transform: 'rotate(0)',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
    },
  },
  EverydayToteLarge: {
    imageName: 'everyday-tote-large',
    fonts: [],
    fontColors: textColorsBags,
    colors: ['1', '2', '3', '4', '5', '6', '7'],
    colorNames: colorNamesEverydayTotes,
    isLuggage: false,
    isMe: false,
    isBag: true,
    isPhone: false,
    chineseCapable: true,
    caseTypeName: 'bag',
    defaultValues: {
      font: 'all-sans',
      text: 'Mine',
      color: 'Green',
      shadow: 'Mint',
      location: 'Back',
    },
    locations: {
      Back: {
        top: '63.3%',
        bottom: 'auto',
        left: '0',
        right: '2%',
        transform: 'rotate(0)',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
    },
  },
  EverydayToteSmall: {
    imageName: 'everyday-tote-small',
    fonts: [],
    fontColors: textColorsBags,
    colors: ['1', '2', '3', '4', '5', '6', '7'],
    colorNames: colorNamesEverydayTotes,
    isLuggage: false,
    isMe: false,
    isBag: true,
    isPhone: false,
    chineseCapable: true,
    caseTypeName: 'bag',
    defaultValues: {
      font: 'all-sans',
      text: 'Mine',
      color: 'Green',
      shadow: 'Mint',
      location: 'Back',
    },
    locations: {
      Back: {
        top: '49%',
        bottom: 'auto',
        left: '0',
        right: '0',
        transform: 'rotate(0)',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
    },
  },
  EverydayToteLargeResort: {
    imageName: 'everyday-tote-large',
    fonts: [],
    fontColors: textColorsBags,
    colors: ['1', '2', '3', '4', '5', '6', '7'],
    colorNames: colorNamesEverydayTotes,
    isLuggage: false,
    isMe: false,
    isBag: true,
    isPhone: false,
    chineseCapable: true,
    caseTypeName: 'bag',
    defaultValues: {
      font: 'all-sans',
      text: 'Mine',
      color: 'Green',
      shadow: 'Mint',
      location: 'Back',
    },
    locations: {
      Back: {
        top: '61%',
        bottom: 'auto',
        left: '0',
        right: '0',
        transform: 'rotate(0)',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
    },
  },
  EverydayToteSmallResort: {
    imageName: 'everyday-tote-small',
    fonts: [],
    fontColors: textColorsBags,
    colors: ['1', '2', '3', '4', '5', '6', '7'],
    colorNames: colorNamesEverydayTotes,
    isLuggage: false,
    isMe: false,
    isBag: true,
    isPhone: false,
    chineseCapable: true,
    caseTypeName: 'bag',
    defaultValues: {
      font: 'all-sans',
      text: 'Mine',
      color: 'Green',
      shadow: 'Mint',
      location: 'Back',
    },
    locations: {
      Back: {
        top: '47.2%',
        bottom: 'auto',
        left: '0',
        right: '0',
        transform: 'rotate(0)',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
    },
  },
  EverydayBackPack: {
    imageName: 'everyday-backpack',
    fonts: [],
    fontColors: textColorsBags,
    colors: ['1', '2', '3', '4', '5', '6', '7'],
    colorNames: colorNamesEverydayTotes,
    isLuggage: false,
    isMe: false,
    isBag: true,
    isPhone: false,
    chineseCapable: true,
    caseTypeName: 'bag',
    defaultValues: {
      font: 'all-sans',
      text: 'Mine',
      color: 'Green',
      shadow: 'Mint',
      location: 'Back',
    },
    locations: {
      Back: {
        top: '57.5%',
        bottom: 'auto',
        left: '0',
        right: '2%',
        transform: 'rotate(0)',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
    },
  },
  EverydayGarment: {
    imageName: 'everyday-garment-tote',
    fonts: [],
    fontColors: textColorsBags,
    colors: ['1', '2', '3', '4', '5', '6', '7'],
    colorNames: colorNamesEverydayTotes,
    isLuggage: false,
    isMe: false,
    isBag: true,
    isPhone: false,
    chineseCapable: true,
    caseTypeName: 'bag',
    defaultValues: {
      font: 'all-sans',
      text: 'Mine',
      color: 'Green',
      shadow: 'Mint',
      location: 'Back',
    },
    locations: {
      Back: {
        top: '49.8%',
        bottom: 'auto',
        left: '0',
        right: '2%',
        transform: 'rotate(0)',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
    },
  },

  'juliette-bucket': {
    imageName: 'juliette-bucket',
    fonts: [],
    fontColors: textColorsBags,
    colors: ['1', '2', '3', '4', '5', '6', '7'],
    colorNames: colorNamesEverydayTotes,
    isLuggage: false,
    isMe: false,
    isBag: true,
    isPhone: false,
    isEmbos: true,
    chineseCapable: true,
    caseTypeName: 'bag',
    defaultValues: {
      font: 'serif-embos',
      text: 'ABC',
      color: 'Gold',
      location: 'Back',
    },
    locations: {
      Back: {
        top: '62%',
        bottom: 'auto',
        left: '0',
        right: '0',
        transform: 'rotate(0)',
        maxLetters: 5,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
    },
  },
  'juliette-large': {
    imageName: 'juliette-large',
    fonts: [],
    fontColors: textColorsBags,
    colors: ['1', '2', '3', '4', '5', '6', '7'],
    colorNames: colorNamesEverydayTotes,
    isLuggage: false,
    isMe: false,
    isBag: true,
    isPhone: false,
    isEmbos: true,
    chineseCapable: true,
    caseTypeName: 'bag',
    defaultValues: {
      font: 'serif-embos',
      text: 'ABC',
      color: 'Gold',
      location: 'Back',
    },
    locations: {
      Back: {
        top: '61.7%',
        bottom: 'auto',
        left: '0',
        right: '0',
        transform: 'rotate(0)',
        maxLetters: 5,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
    },
  },
  'juliette-small': {
    imageName: 'juliette-small',
    fonts: [],
    fontColors: textColorsBags,
    colors: ['1', '2', '3', '4', '5', '6', '7'],
    colorNames: colorNamesEverydayTotes,
    isLuggage: false,
    isMe: false,
    isBag: true,
    isPhone: false,
    isEmbos: true,
    chineseCapable: true,
    caseTypeName: 'bag',
    defaultValues: {
      font: 'serif-embos',
      text: 'ABC',
      color: 'Gold',
      location: 'Back',
    },
    locations: {
      Back: {
        top: '61.7%',
        bottom: 'auto',
        left: '0',
        right: '0',
        transform: 'rotate(0)',
        maxLetters: 5,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
    },
  },
  'juliette-spacious': {
    imageName: 'juliette-spacious',
    fonts: [],
    fontColors: textColorsBags,
    colors: ['1', '2', '3', '4', '5', '6', '7'],
    colorNames: colorNamesEverydayTotes,
    isLuggage: false,
    isMe: false,
    isBag: true,
    isPhone: false,
    isEmbos: true,
    chineseCapable: true,
    caseTypeName: 'bag',
    defaultValues: {
      font: 'serif-embos',
      text: 'ABC',
      color: 'Gold',
      location: 'Back',
    },
    locations: {
      Back: {
        top: '59.5%',
        bottom: 'auto',
        left: '0',
        right: '0',
        transform: 'rotate(0)',
        maxLetters: 5,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
    },
  },
  'juliette-work': {
    imageName: 'juliette-work',
    fonts: [],
    fontColors: textColorsBags,
    colors: ['1', '2', '3', '4', '5', '6', '7'],
    colorNames: colorNamesEverydayTotes,
    isLuggage: false,
    isMe: false,
    isBag: true,
    isPhone: false,
    isEmbos: true,
    chineseCapable: true,
    caseTypeName: 'bag',
    defaultValues: {
      font: 'serif-embos',
      text: 'ABC',
      color: 'Gold',
      location: 'Back',
    },
    locations: {
      Back: {
        top: '62.5%',
        bottom: 'auto',
        left: '0',
        right: '0',
        transform: 'rotate(0)',
        maxLetters: 5,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
    },
  },

  //Juliette
  'large-juliette': {
    imageName: 'large-juliette',
    fonts: [],
    fontColors: textColorsBags,
    colors: ['1', '2', '3', '4', '5', '6', '7'],
    colorNames: colorNamesEverydayTotes,
    isLuggage: false,
    isMe: false,
    isBag: true,
    isPhone: false,
    isEmbos: true,
    chineseCapable: true,
    caseTypeName: 'bag',
    defaultValues: {
      font: 'serif-embos',
      text: 'ABC',
      color: 'Gold',
      location: 'Back',
    },
    locations: {
      Back: {
        top: '61.7%',
        bottom: 'auto',
        left: '0',
        right: '0',
        transform: 'rotate(0)',
        maxLetters: 5,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
    },
  },
  'small-juliette': {
    imageName: 'small-juliette',
    fonts: [],
    fontColors: textColorsBags,
    colors: ['1', '2', '3', '4', '5', '6', '7'],
    colorNames: colorNamesEverydayTotes,
    isLuggage: false,
    isMe: false,
    isBag: true,
    isPhone: false,
    isEmbos: true,
    chineseCapable: true,
    caseTypeName: 'bag',
    defaultValues: {
      font: 'serif-embos',
      text: 'ABC',
      color: 'Gold',
      location: 'Back',
    },
    locations: {
      Back: {
        top: '55.3%',
        bottom: 'auto',
        left: '0',
        right: '-2%',
        transform: 'rotate(0)',
        maxLetters: 5,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
    },
  },
  'crossbody-juliette': {
    imageName: 'crossbody-juliette',
    fonts: [],
    fontColors: textColorsBags,
    colors: ['1', '2', '3', '4', '5', '6', '7'],
    colorNames: colorNamesEverydayTotes,
    isLuggage: false,
    isMe: false,
    isBag: true,
    isPhone: false,
    isEmbos: true,
    chineseCapable: true,
    caseTypeName: 'bag',
    defaultValues: {
      font: 'serif-embos',
      text: 'ABC',
      color: 'Gold',
      location: 'Back',
    },
    locations: {
      Back: {
        top: '40%',
        bottom: 'auto',
        left: '0',
        right: '3%',
        transform: 'rotate(0)',
        maxLetters: 3,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
    },
  },
  EverydayToteMini: {
    imageName: 'everyday-tote-mini',
    fonts: [],
    fontColors: textColorsBags,
    colors: ['1', '2', '3', '4', '5', '6', '7'],
    colorNames: colorNamesEverydayTotes,
    isLuggage: false,
    isMe: false,
    isBag: true,
    isPhone: false,
    chineseCapable: true,
    caseTypeName: 'bag',
    defaultValues: {
      font: 'Serif',
      text: 'ABC',
      color: 'Green',
      shadow: 'Mint',
      location: 'Back',
    },
    locations: {
      Back: {
        top: '58%',
        bottom: 'auto',
        left: '0',
        right: '0',
        transform: 'rotate(0)',
        maxLetters: 3,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
    },
  },
  // Everyday Tote Leather
  EverydayToteMiniLeather: {
    imageName: 'everyday-tote-mini',
    fonts: [],
    fontColors: textColorsBags,
    colors: ['1', '2', '3', '4', '5', '6', '7'],
    colorNames: colorNamesEverydayTotes,
    isLuggage: false,
    isMe: false,
    isBag: true,
    isPhone: false,
    chineseCapable: true,
    caseTypeName: 'bag',
    defaultValues: {
      font: 'Serif',
      text: 'ABC',
      color: 'Green',
      shadow: 'Mint',
      location: 'Back',
    },
    locations: {
      Back: {
        top: '58%',
        bottom: 'auto',
        left: '0',
        right: '1%',
        transform: 'rotate(0)',
        maxLetters: 3,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
    },
  },
  EverydayToteLargeLeather: {
    imageName: 'everyday-tote-large',
    fonts: [],
    fontColors: textColorsBags,
    colors: ['1', '2', '3', '4', '5', '6', '7'],
    colorNames: colorNamesEverydayTotes,
    isLuggage: false,
    isMe: false,
    isBag: true,
    isPhone: false,
    chineseCapable: true,
    caseTypeName: 'bag',
    defaultValues: {
      font: 'all-sans',
      text: 'Mine',
      color: 'Green',
      shadow: 'Mint',
      location: 'Back',
    },
    locations: {
      Back: {
        top: '62%',
        bottom: 'auto',
        left: '0',
        right: '2%',
        transform: 'rotate(0)',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
    },
  },
  EverydayToteSmallLeather: {
    imageName: 'everyday-tote-small',
    fonts: [],
    fontColors: textColorsBags,
    colors: ['1', '2', '3', '4', '5', '6', '7'],
    colorNames: colorNamesEverydayTotes,
    isLuggage: false,
    isMe: false,
    isBag: true,
    isPhone: false,
    chineseCapable: true,
    caseTypeName: 'bag',
    defaultValues: {
      font: 'all-sans',
      text: 'Mine',
      color: 'Green',
      shadow: 'Mint',
      location: 'Back',
    },
    locations: {
      Back: {
        top: '46.5%',
        bottom: 'auto',
        left: '0',
        right: '0',
        transform: 'rotate(0)',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
    },
  },
  BeltBag: {
    imageName: 'belt-bag',
    fonts: [],
    fontColors: textColorsBags,
    colors: ['1', '2', '3', '4', '5', '6', '7'],
    colorNames: colorNamesEverydayTotes,
    isLuggage: false,
    isMe: false,
    isBag: true,
    isPhone: false,
    chineseCapable: true,
    caseTypeName: 'bag',
    defaultValues: {
      font: 'Serif',
      text: 'ABC',
      color: 'Green',
      shadow: 'Mint',
      location: 'Back',
    },
    locations: {
      Back: {
        top: '40%',
        bottom: 'auto',
        left: '0',
        right: '3%',
        transform: 'rotate(0)',
        maxLetters: 3,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
    },
  },
  CrescentBag: {
    imageName: 'crescent-bag',
    fonts: [],
    fontColors: textColorsBags,
    colors: ['1', '2', '3', '4', '5', '6', '7'],
    colorNames: colorNamesEverydayTotes,
    isLuggage: false,
    isMe: false,
    isBag: true,
    isPhone: false,
    chineseCapable: true,
    caseTypeName: 'bag',
    defaultValues: {
      font: 'Serif',
      text: 'ABC',
      color: 'Green',
      shadow: 'Mint',
      location: 'Back',
    },
    locations: {
      Back: {
        top: '74%',
        bottom: 'auto',
        left: '0',
        right: '0',
        transform: 'rotate(0)',
        maxLetters: 5,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
    },
  },
  Daypack: {
    imageName: 'Daypack',
    fonts: [],
    fontColors: textColorsBags,
    colors: ['1', '2', '3', '4'],
    colorNames: colorNamesBags,
    isLuggage: false,
    isMe: false,
    isBag: true,
    isPhone: false,
    chineseCapable: true,
    caseTypeName: 'bag',
    defaultValues: {
      font: 'all-sans',
      text: 'Mine',
      color: 'Green',
      shadow: 'Mint',
      location: 'Back',
    },
    locations: {
      Back: {
        top: '57.5%',
        bottom: 'auto',
        left: '0.5%',
        right: '0',
        transform: 'rotate(0)',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
    },
  },
  Me: {
    imageName: 'Me',
    fonts: [],
    fontColors: textColorsLuggage,
    colors: ['1', '2', '3', '4', '5', '6', '7', '8'],
    colorNames: colorNamesLuggage,
    isLuggage: true,
    isMe: true,
    isBag: false,
    isPhone: false,
    chineseCapable: true,
    caseTypeName: 'case',
    defaultValues: {
      font: 'heritage',
      text: 'Mine',
      color: 'Green',
      shadow: 'Mint',
      location: 'Back',
    },
    locations: {
      Back: {
        top: '59.5%',
        bottom: 'auto',
        left: '25.5%',
        right: '0',
        transform: 'rotate(0)',
        maxLetters: 20,
        displaySize: {
          height: 25,
          width: 100,
          padding: {
            top: 4.1,
            bottom: 4.1,
            left: 7,
            right: 7,
          },
        },
        previewSize: {
          height: 80,
          width: 320,
          padding: {
            top: 13.3,
            bottom: 13.3,
            left: 18,
            right: 18,
          },
        },
      },
    },
  },
  'tech-kit': {
    imageName: 'tech-kit',
    fonts: [],
    fontColors: textColorsLuggage,
    colors: ['1', '2', '3', '4', '5', '6', '7', '8'],
    colorNames: colorNamesLuggage,
    isLuggage: true,
    isMe: true,
    isBag: false,
    isPhone: false,
    chineseCapable: true,
    caseTypeName: 'case',
    defaultValues: {
      font: 'heritage',
      text: 'Mine',
      color: 'Green',
      shadow: 'Mint',
      location: 'Right',
    },
    locations: {
      Right: {
        top: '60.5%',
        bottom: 'auto',
        left: '31.5%',
        right: '0',
        transform: 'rotate(0)',
        maxLetters: 20,
        displaySize: {
          height: 35,
          width: 140,
          padding: {
            top: 4.1,
            bottom: 4.1,
            left: 7,
            right: 7,
          },
        },
        previewSize: {
          height: 80,
          width: 320,
          padding: {
            top: 13.3,
            bottom: 13.3,
            left: 18,
            right: 18,
          },
        },
      },
      Left: {
        top: '60.5%',
        bottom: 'auto',
        right: '29.5%',
        left: '0',
        transform: 'rotate(0)',
        maxLetters: 20,
        displaySize: {
          height: 35,
          width: 140,
          padding: {
            top: 4.1,
            bottom: 4.1,
            left: 7,
            right: 7,
          },
        },
        previewSize: {
          height: 80,
          width: 320,
          padding: {
            top: 13.3,
            bottom: 13.3,
            left: 18,
            right: 18,
          },
        },
      },
    },
  },
  'Me-V2': {
    imageName: 'me-v2',
    fonts: [],
    fontColors: textColorsLuggage,
    colors: ['1', '2', '3', '4', '5', '6', '7', '8'],
    colorNames: colorNamesLuggage,
    isLuggage: true,
    isMe: true,
    isBag: false,
    isPhone: false,
    chineseCapable: true,
    caseTypeName: 'case',
    defaultValues: {
      font: 'heritage',
      text: 'Mine',
      color: 'Green',
      shadow: 'Mint',
      location: 'Right',
    },
    locations: {
      Right: {
        top: '60.5%',
        bottom: 'auto',
        left: '31.5%',
        right: '0',
        transform: 'rotate(0)',
        maxLetters: 20,
        displaySize: {
          height: 35,
          width: 140,
          padding: {
            top: 4.1,
            bottom: 4.1,
            left: 7,
            right: 7,
          },
        },
        previewSize: {
          height: 80,
          width: 320,
          padding: {
            top: 13.3,
            bottom: 13.3,
            left: 18,
            right: 18,
          },
        },
      },
      Left: {
        top: '60.5%',
        bottom: 'auto',
        right: '29.5%',
        left: '0',
        transform: 'rotate(0)',
        maxLetters: 20,
        displaySize: {
          height: 35,
          width: 140,
          padding: {
            top: 4.1,
            bottom: 4.1,
            left: 7,
            right: 7,
          },
        },
        previewSize: {
          height: 80,
          width: 320,
          padding: {
            top: 13.3,
            bottom: 13.3,
            left: 18,
            right: 18,
          },
        },
      },
    },
  },

  Bottle: {
    imageName: 'Bottle',
    fonts: [],
    fontColors: textColorsLuggage,
    colors: ['1', '2', '3', '4'],
    colorNames: colorNamesLuggage,
    isLuggage: true,
    isMe: false,
    isBottle: true,
    isBag: false,
    isPhone: false,
    chineseCapable: true,
    caseTypeName: 'bottle',
    defaultValues: {
      font: 'heritage',
      text: 'Mine',
      color: 'Green',
      shadow: 'Mint',
      location: 'Back',
    },
    locations: {
      Back: {
        top: '50.5%',
        bottom: 'auto',
        left: '0.5%',
        right: '0',
        transform: 'rotate(-90deg)',
        maxLetters: 20,
        displaySize: {
          height: 60,
          width: 260,
          padding: {
            top: 4.1,
            bottom: 4.1,
            left: 7,
            right: 7,
          },
        },
        previewSize: {
          height: 80,
          width: 320,
          padding: {
            top: 13.3,
            bottom: 13.3,
            left: 18,
            right: 18,
          },
        },
      },
      Top: {
        top: '38%',
        bottom: 'auto',
        left: '1.5%',
        right: '0',
        transform: 'rotate(0)',
        maxLetters: 2,
        square: true,
        displaySize: {
          height: 120,
          width: 120,
          padding: {
            top: 15,
            bottom: 15,
            left: 15,
            right: 15,
          },
        },
        previewSize: {
          height: 250,
          width: 250,
          padding: {
            top: 13.3,
            bottom: 13.3,
            left: 18,
            right: 18,
          },
        },
      },
    },
  },
  'bottle-750': {
    imageName: 'bottle-750',
    fonts: [],
    fontColors: textColorsLuggage,
    colors: ['1', '2'],
    colorNames: colorNamesLuggage,
    isLuggage: true,
    isMe: false,
    isBottle: true,
    isBag: false,
    isPhone: false,
    chineseCapable: true,
    caseTypeName: 'bottle',
    defaultValues: {
      font: 'heritage',
      text: 'Mine',
      color: 'Green',
      shadow: 'Mint',
      location: 'Back',
    },
    locations: {
      Back: {
        top: '50.5%',
        bottom: 'auto',
        left: '0.5%',
        right: '0',
        transform: 'rotate(-90deg)',
        maxLetters: 20,
        displaySize: {
          height: 60,
          width: 260,
          padding: {
            top: 4.1,
            bottom: 4.1,
            left: 7,
            right: 7,
          },
        },
        previewSize: {
          height: 80,
          width: 320,
          padding: {
            top: 13.3,
            bottom: 13.3,
            left: 18,
            right: 18,
          },
        },
      },
      Top: {
        top: '38%',
        bottom: 'auto',
        left: '1.5%',
        right: '0',
        transform: 'rotate(0)',
        maxLetters: 2,
        square: true,
        displaySize: {
          height: 120,
          width: 120,
          padding: {
            top: 15,
            bottom: 15,
            left: 15,
            right: 15,
          },
        },
        previewSize: {
          height: 250,
          width: 250,
          padding: {
            top: 13.3,
            bottom: 13.3,
            left: 18,
            right: 18,
          },
        },
      },
    },
  },
  'iphone-mini': {
    imageName: 'iphone-mini',
    fonts: [],
    fontColors: textColorsLuggage,
    colors: ['1', '2', '3', '4'],
    colorNames: colorNamesLuggage,
    isLuggage: true,
    isMe: false,
    isBottle: false,
    isBag: false,
    isPhone: true,
    chineseCapable: true,
    caseTypeName: 'case',
    defaultValues: {
      font: 'heritage',
      text: 'Mine',
      color: 'Green',
      shadow: 'Mint',
      location: 'Side',
    },
    locations: {
      Back: {
        top: '35.5%',
        bottom: 'auto',
        left: '0%',
        right: '0',
        transform: '',
        maxLetters: 2,
        displaySize: phoneBackDisplaySize,
        previewSize: phoneBackPreviewSize,
      },
      Side: {
        top: '39%',
        bottom: 'auto',
        left: '-15%',
        right: '0',
        transform: 'rotate(0)',
        maxLetters: 20,
        square: true,
        displaySize: phoneSideDisplaySize,
        previewSize: phoneSidePreviewSize,
      },
    },
  },

  'iphone-classic': {
    imageName: 'iphone-classic',
    fonts: [],
    fontColors: textColorsLuggage,
    colors: ['1', '2', '3', '4'],
    colorNames: colorNamesLuggage,
    isLuggage: true,
    isMe: false,
    isBottle: false,
    isBag: false,
    isPhone: true,
    chineseCapable: true,
    caseTypeName: 'case',
    defaultValues: {
      font: 'heritage',
      text: 'Mine',
      color: 'Green',
      shadow: 'Mint',
      location: 'Side',
    },
    locations: {
      Back: {
        top: '33.5%',
        bottom: 'auto',
        left: '1%',
        right: '0',
        transform: '',
        maxLetters: 2,
        displaySize: phoneBackDisplaySize,
        previewSize: phoneBackPreviewSize,
      },
      Side: {
        top: '38%',
        bottom: 'auto',
        left: '-12%',
        right: '0',
        transform: 'rotate(0)',
        maxLetters: 20,
        square: true,
        displaySize: phoneSideDisplaySize,
        previewSize: phoneSidePreviewSize,
      },
    },
  },

  'iphone-max': {
    imageName: 'iphone-max',
    fonts: [],
    fontColors: textColorsLuggage,
    colors: ['1', '2', '3', '4'],
    colorNames: colorNamesLuggage,
    isLuggage: true,
    isMe: false,
    isBottle: false,
    isBag: false,
    isPhone: true,
    chineseCapable: true,
    caseTypeName: 'case',
    defaultValues: {
      font: 'heritage',
      text: 'Mine',
      color: 'Green',
      shadow: 'Mint',
      location: 'Side',
    },
    locations: {
      Back: {
        top: '35.5%',
        bottom: 'auto',
        left: '9%',
        right: '0',
        transform: '',
        maxLetters: 2,
        displaySize: phoneBackDisplaySize,
        previewSize: phoneBackPreviewSize,
      },
      Side: {
        top: '39%',
        bottom: 'auto',
        left: '-15%',
        right: '0',
        transform: 'rotate(0)',
        maxLetters: 20,
        square: true,
        displaySize: phoneSideDisplaySize,
        previewSize: phoneSidePreviewSize,
      },
    },
  },
  'trunk-pro': {
    imageName: 'trunk-pro',
    fonts: [],
    fontColors: textColorsLuggage,
    colors: ['1', '2', '3'],
    colorNames: colorNamesLuggage,
    isLuggage: true,
    isMe: false,
    isBottle: false,
    isBag: false,
    isPhone: false,
    isTrunk: true,
    chineseCapable: true,
    caseTypeName: 'case',
    defaultValues: {
      font: 'heritage',
      text: 'Mine',
      color: 'Green',
      shadow: 'Mint',
      location: 'Back',
    },
    locations: {
      Back: {
        top: '24%',
        bottom: 'auto',
        left: '0%',
        right: '0',
        transform: '',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
      Top: {
        top: '15%',
        bottom: 'auto',
        left: '0%',
        right: '0',
        transform: 'rotate(0)',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
    },
  },
  'trunk-carry': {
    imageName: 'trunk-carry',
    fonts: [],
    fontColors: textColorsLuggage,
    colors: ['1', '2', '3'],
    colorNames: colorNamesLuggage,
    isLuggage: true,
    isMe: false,
    isBottle: false,
    isBag: false,
    isPhone: false,
    isTrunk: true,
    chineseCapable: true,
    caseTypeName: 'case',
    defaultValues: {
      font: 'heritage',
      text: 'Mine',
      color: 'Green',
      shadow: 'Mint',
      location: 'Back',
    },
    locations: {
      Back: {
        top: '70%',
        bottom: 'auto',
        left: '0%',
        right: '0',
        transform: '',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
      Top: {
        top: '11%',
        bottom: 'auto',
        left: '0%',
        right: '0',
        transform: 'rotate(0)',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
    },
  },
  'trunk-checked-plus': {
    imageName: 'trunk-checked-plus',
    fonts: [],
    fontColors: textColorsLuggage,
    colors: ['1', '2', '3'],
    colorNames: colorNamesLuggage,
    isLuggage: true,
    isMe: false,
    isBottle: false,
    isBag: false,
    isPhone: false,
    isTrunk: true,
    chineseCapable: true,
    caseTypeName: 'case',
    defaultValues: {
      font: 'heritage',
      text: 'Mine',
      color: 'Green',
      shadow: 'Mint',
      location: 'Side',
    },
    locations: {
      Top: {
        top: '16%',
        bottom: 'auto',
        left: '0%',
        right: '0',
        transform: '',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
      Side: {
        top: '27%',
        bottom: 'auto',
        left: '-34%',
        right: '0',
        transform: 'rotate(-90deg)',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
    },
  },
  'trunk-checked': {
    imageName: 'trunk-checked',
    fonts: [],
    fontColors: textColorsLuggage,
    colors: ['1', '2', '3'],
    colorNames: colorNamesLuggage,
    isLuggage: true,
    isMe: false,
    isBottle: false,
    isBag: false,
    isPhone: false,
    isTrunk: true,
    chineseCapable: true,
    caseTypeName: 'case',
    defaultValues: {
      font: 'heritage',
      text: 'Mine',
      color: 'Green',
      shadow: 'Mint',
      location: 'Side',
    },
    locations: {
      Top: {
        top: '12%',
        bottom: 'auto',
        left: '0%',
        right: '0',
        transform: '',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
      Side: {
        top: '49%',
        bottom: 'auto',
        left: '21%',
        right: '0',
        transform: 'rotate(-90deg)',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
    },
  },
  'trunk-carry-heritage': {
    imageName: 'trunk-carry-heritage',
    fonts: [],
    fontColors: textColorsLuggage,
    colors: ['1', '2', '3'],
    colorNames: colorNamesLuggage,
    isLuggage: true,
    isMe: false,
    isBottle: false,
    isBag: false,
    isPhone: false,
    isTrunk: true,
    chineseCapable: true,
    caseTypeName: 'case',
    defaultValues: {
      font: 'heritage',
      text: 'Mine',
      color: 'Green',
      shadow: 'Mint',
      location: 'Back',
    },
    locations: {
      Back: {
        top: '70%',
        bottom: 'auto',
        left: '0%',
        right: '0',
        transform: '',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
      Top: {
        top: '11%',
        bottom: 'auto',
        left: '0%',
        right: '0',
        transform: 'rotate(0)',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
    },
  },
  'trunk-carry-limited': {
    imageName: 'trunk-carry-limited',
    fonts: [],
    fontColors: textColorsLuggage,
    colors: ['1', '2', '3'],
    colorNames: colorNamesLuggage,
    isLuggage: true,
    isMe: false,
    isBottle: false,
    isBag: false,
    isPhone: false,
    isTrunk: true,
    chineseCapable: true,
    caseTypeName: 'case',
    defaultValues: {
      font: 'heritage',
      text: 'Mine',
      color: 'Green',
      shadow: 'Mint',
      location: 'Back',
    },
    locations: {
      Back: {
        top: '24%',
        bottom: 'auto',
        left: '0%',
        right: '2%',
        transform: '',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
      Top: {
        top: '15%',
        bottom: 'auto',
        left: '0%',
        right: '0',
        transform: 'rotate(0)',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
    },
  },
  'trunk-checked-heritage': {
    imageName: 'trunk-checked-heritage',
    fonts: [],
    fontColors: textColorsLuggage,
    colors: ['1', '2', '3'],
    colorNames: colorNamesLuggage,
    isLuggage: true,
    isMe: false,
    isBottle: false,
    isBag: false,
    isPhone: false,
    isTrunk: true,
    chineseCapable: true,
    caseTypeName: 'case',
    defaultValues: {
      font: 'heritage',
      text: 'Mine',
      color: 'Green',
      shadow: 'Mint',
      location: 'Side',
    },
    locations: {
      Top: {
        top: '12%',
        bottom: 'auto',
        left: '0%',
        right: '0',
        transform: '',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
      Side: {
        top: '49%',
        bottom: 'auto',
        left: '21%',
        right: '0',
        transform: 'rotate(-90deg)',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
    },
  },
  'trunk-carry-matte': {
    imageName: 'trunk-carry-matte',
    fonts: [],
    fontColors: textColorsLuggage,
    colors: ['1', '2', '3'],
    colorNames: colorNamesLuggage,
    isLuggage: true,
    isMe: false,
    isBottle: false,
    isBag: false,
    isPhone: false,
    isTrunk: true,
    chineseCapable: true,
    caseTypeName: 'case',
    defaultValues: {
      font: 'heritage',
      text: 'Mine',
      color: 'Green',
      shadow: 'Mint',
      location: 'Top',
    },
    locations: {
      Back: {
        top: '70%',
        bottom: 'auto',
        left: '0%',
        right: '0',
        transform: '',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
      Top: {
        top: '35%',
        bottom: 'auto',
        left: '0%',
        right: '0',
        transform: 'rotate(0)',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
    },
  },
  'trunk-carry-matte-new': {
    imageName: 'trunk-carry-matte',
    fonts: [],
    fontColors: textColorsLuggage,
    colors: ['1', '2', '3'],
    colorNames: colorNamesLuggage,
    isLuggage: true,
    isMe: false,
    isBottle: false,
    isBag: false,
    isPhone: false,
    isTrunk: true,
    chineseCapable: true,
    caseTypeName: 'case',
    defaultValues: {
      font: 'heritage',
      text: 'Mine',
      color: 'Green',
      shadow: 'Mint',
      location: 'Top',
    },
    locations: {
      Back: {
        top: '25%',
        bottom: 'auto',
        left: '0%',
        right: '0',
        transform: '',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
      Top: {
        top: '15%',
        bottom: 'auto',
        left: '0%',
        right: '0',
        transform: 'rotate(0)',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
    },
  },

  'trunk-checked-matte': {
    imageName: 'trunk-checked-matte',
    fonts: [],
    fontColors: textColorsLuggage,
    colors: ['1', '2', '3'],
    colorNames: colorNamesLuggage,
    isLuggage: true,
    isMe: false,
    isBottle: false,
    isBag: false,
    isPhone: false,
    isTrunk: true,
    chineseCapable: true,
    caseTypeName: 'case',
    defaultValues: {
      font: 'heritage',
      text: 'Mine',
      color: 'Green',
      shadow: 'Mint',
      location: 'Side',
    },
    locations: {
      Top: {
        top: '21.5%',
        bottom: 'auto',
        left: '0%',
        right: '0',
        transform: '',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
      Side: {
        top: '49%',
        bottom: 'auto',
        left: '21%',
        right: '0',
        transform: 'rotate(-90deg)',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
    },
  },
  'trunk-checked-matte-new': {
    imageName: 'trunk-checked-matte',
    fonts: [],
    fontColors: textColorsLuggage,
    colors: ['1', '2', '3'],
    colorNames: colorNamesLuggage,
    isLuggage: true,
    isMe: false,
    isBottle: false,
    isBag: false,
    isPhone: false,
    isTrunk: true,
    chineseCapable: true,
    caseTypeName: 'case',
    defaultValues: {
      font: 'heritage',
      text: 'Mine',
      color: 'Green',
      shadow: 'Mint',
      location: 'Side',
    },
    locations: {
      Top: {
        top: '15%',
        bottom: 'auto',
        left: '0%',
        right: '0',
        transform: '',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
      Side: {
        top: '25%',
        bottom: 'auto',
        left: '23%',
        right: '0',
        transform: 'rotate(-90deg)',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
    },
  },
  'trunk-checked-limited': {
    imageName: 'trunk-checked-limited',
    fonts: [],
    fontColors: textColorsLuggage,
    colors: ['1', '2', '3'],
    colorNames: colorNamesLuggage,
    isLuggage: true,
    isMe: false,
    isBottle: false,
    isBag: false,
    isPhone: false,
    isTrunk: true,
    chineseCapable: true,
    caseTypeName: 'case',
    defaultValues: {
      font: 'heritage',
      text: 'Mine',
      color: 'Green',
      shadow: 'Mint',
      location: 'Side',
    },
    locations: {
      Top: {
        top: '14%',
        bottom: 'auto',
        left: '0%',
        right: '0',
        transform: '',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
      Side: {
        top: '25%',
        bottom: 'auto',
        left: '24%',
        right: '0',
        transform: 'rotate(-90deg)',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
    },
  },
  'trunk-set': {
    imageName: 'trunk-checked',
    fonts: [],
    fontColors: textColorsLuggage,
    colors: ['1', '2', '3'],
    colorNames: colorNamesLuggage,
    isLuggage: true,
    isMe: false,
    isBottle: false,
    isBag: false,
    isPhone: false,
    isTrunk: true,
    chineseCapable: true,
    caseTypeName: 'case',
    defaultValues: {
      font: 'heritage',
      text: 'Mine',
      color: 'Green',
      shadow: 'Mint',
      location: 'Top',
    },
    locations: {
      Top: {
        top: '12%',
        bottom: 'auto',
        left: '0%',
        right: '0',
        transform: '',
        maxLetters: 20,
        displaySize: defaultDisplaySize,
        previewSize: defaultPreviewSize,
      },
    },
  },
  notebook: {
    fonts: [],
    fontColors: textColorsLuggage,
    colors: ['1', '2', '3'],
    colorNames: colorNamesLuggage,
    isLuggage: true,
    isMe: false,
    isBottle: false,
    isBag: false,
    isPhone: false,
    isTrunk: true,
    chineseCapable: true,
    caseTypeName: 'notebook',
    defaultValues: {
      font: 'heritage',
      text: 'Mine',
      color: 'Green',
      shadow: 'Mint',
      location: 'center-landscape',
    },
    locations: {
      'center-landscape': {
        top: '44%',
        bottom: 'auto',
        left: '5%',
        right: '0',
        transform: '',
        maxLetters: 20,
        displaySize: {
          height: 55,
          width: 280,
          padding: {
            top: 4.1,
            bottom: 4.1,
            left: 7,
            right: 7,
          },
        },
        previewSize: {
          height: 80,
          width: 640,
          padding: {
            top: 13.3,
            bottom: 13.3,
            left: 18,
            right: 18,
          },
        },
      },
      'center-portrait': {
        top: '43%',
        bottom: 'auto',
        left: '0%',
        right: '0',
        transform: '',
        maxLetters: 20,
        displaySize: {
          height: 40,
          width: 155,
          padding: {
            top: 4.1,
            bottom: 4.1,
            left: 7,
            right: 7,
          },
        },
        previewSize: {
          height: 80,
          width: 640,
          padding: {
            top: 13.3,
            bottom: 13.3,
            left: 18,
            right: 18,
          },
        },
      },
      'bottom-left': {
        top: 'auto',
        bottom: '20%',
        left: '-4%',
        right: '0',
        maxLetters: 20,
        displaySize: {
          height: 40,
          width: 160,
          padding: {
            top: 4.1,
            bottom: 4.1,
            left: 7,
            right: 7,
          },
        },
        previewSize: {
          height: 80,
          width: 640,
          padding: {
            top: 13.3,
            bottom: 13.3,
            left: 18,
            right: 18,
          },
        },
        leftAlign: true,
      },
    },
  },
  'luggage-tag': {
    imageName: 'luggage-tag',
    fonts: [],
    fontColors: textColorsLuggage,
    colors: ['1', '2'],
    colorNames: colorNamesLuggage,
    isLuggage: true,
    isMe: false,
    isBottle: false,
    isBag: false,
    isPhone: true,
    chineseCapable: true,
    caseTypeName: 'tag',
    defaultValues: {
      font: 'heritage',
      text: 'Mine',
      color: 'Green',
      shadow: 'Mint',
      location: 'Side',
    },
    locations: {
      Back: {
        top: '40.5%',
        bottom: 'auto',
        left: '2.5%',
        right: '0',
        transform: '',
        maxLetters: 2,
        displaySize: tagBackDisplaySize,
        previewSize: tagBackPreviewSize,
      },
      Side: {
        top: '38.5%',
        bottom: 'auto',
        left: '25%',
        right: '0',
        transform: 'rotate(0)',
        maxLetters: 20,
        square: true,
        displaySize: tagSideDisplaySize,
        previewSize: tagSidePreviewSize,
      },
    },
  },
  'arch-tag': {
    imageName: 'arch-tag',
    fonts: [],
    fontColors: textColorsLuggage,
    colors: ['1', '2'],
    colorNames: colorNamesLuggage,
    isLuggage: true,
    isMe: false,
    isBottle: false,
    isBag: false,
    isPhone: false,
    chineseCapable: true,
    caseTypeName: 'tag',
    defaultValues: {
      font: 'Serif',
      text: 'A.B',
      color: 'Yellow',
      shadow: 'Mint',
      location: 'Vertical',
    },
    locations: {
      Vertical: {
        top: '56%',
        bottom: 'auto',
        left: '0%',
        right: '0',
        transform: '',
        maxLetters: 3,
        displaySize: tagBackDisplaySize,
        previewSize: tagBackPreviewSize,
      },
    },
  },
  'circle-tag': {
    imageName: 'circle-tag',
    fonts: [],
    fontColors: textColorsLuggage,
    colors: ['1', '2'],
    colorNames: colorNamesLuggage,
    isLuggage: true,
    isMe: false,
    isBottle: false,
    isBag: false,
    isPhone: false,
    chineseCapable: true,
    caseTypeName: 'tag',
    defaultValues: {
      font: 'Serif',
      text: 'A.B',
      color: 'Yellow',
      shadow: 'Mint',
      location: 'Vertical',
    },
    locations: {
      Vertical: {
        top: '58%',
        bottom: 'auto',
        left: '0%',
        right: '0',
        transform: '',
        maxLetters: 3,
        displaySize: tagBackDisplaySize,
        previewSize: tagBackPreviewSize,
      },
    },
  },
  'classic-tag': {
    imageName: 'classic-tag',
    fonts: [],
    fontColors: textColorsLuggage,
    colors: ['1', '2'],
    colorNames: colorNamesLuggage,
    isLuggage: true,
    isMe: false,
    isBottle: false,
    isBag: false,
    isPhone: false,
    chineseCapable: true,
    caseTypeName: 'tag',
    defaultValues: {
      font: 'Serif',
      text: 'A.B',
      color: 'Yellow',
      shadow: 'Mint',
      location: 'Vertical',
    },
    locations: {
      Vertical: {
        top: '59%',
        bottom: 'auto',
        left: '0%',
        right: '0',
        transform: '',
        maxLetters: 3,
        displaySize: tagBackDisplaySize,
        previewSize: tagBackPreviewSize,
      },
    },
  },
  'bow-tag': {
    imageName: 'bow-tag',
    fonts: [],
    fontColors: textColorsLuggage,
    colors: ['1', '2'],
    colorNames: colorNamesLuggage,
    isLuggage: true,
    isMe: false,
    isBottle: false,
    isBag: false,
    isPhone: false,
    chineseCapable: true,
    caseTypeName: 'tag',
    defaultValues: {
      font: 'Serif',
      text: 'A.B',
      color: 'Yellow',
      shadow: 'Mint',
      location: 'Vertical',
    },
    locations: {
      Vertical: {
        top: '61%',
        bottom: 'auto',
        left: '57%',
        right: '',
        transform: 'rotate(45deg)',
        maxLetters: 3,
        displaySize: bowTagBackDisplaySize,
        previewSize: bowTagBackPreviewSize,
      },
    },
  },
  'key-hotel': {
    imageName: 'key-hotel',
    fonts: [],
    fontColors: textColorsLuggage,
    colors: ['1', '2'],
    colorNames: colorNamesLuggage,
    isLuggage: true,
    isMe: false,
    isBottle: false,
    isBag: false,
    isPhone: false,
    chineseCapable: true,
    caseTypeName: 'tag',
    defaultValues: {
      font: 'Serif',
      text: 'A.B',
      color: 'Yellow',
      shadow: 'Mint',
      location: 'Front',
    },
    locations: {
      Front: {
        top: '56%',
        bottom: 'auto',
        left: '0%',
        right: '0',
        transform: '',
        maxLetters: 3,
        displaySize: keyRingsBackDisplaySize,
        previewSize: keyRingsBackPreviewSize,
      },
    },
  },
  'key-circle': {
    imageName: 'key-circle',
    fonts: [],
    fontColors: textColorsLuggage,
    colors: ['1', '2'],
    colorNames: colorNamesLuggage,
    isLuggage: true,
    isMe: false,
    isBottle: false,
    isBag: false,
    isPhone: false,
    chineseCapable: true,
    caseTypeName: 'tag',
    defaultValues: {
      font: 'Serif',
      text: 'A.B',
      color: 'Yellow',
      shadow: 'Mint',
      location: 'Front',
    },
    locations: {
      Front: {
        top: '56%',
        bottom: 'auto',
        left: '0%',
        right: '0',
        transform: '',
        maxLetters: 3,
        displaySize: keyRingsBackDisplaySize,
        previewSize: keyRingsBackPreviewSize,
      },
    },
  },
  'key-flower': {
    imageName: 'key-flower',
    fonts: [],
    fontColors: textColorsLuggage,
    colors: ['1', '2'],
    colorNames: colorNamesLuggage,
    isLuggage: true,
    isMe: false,
    isBottle: false,
    isBag: false,
    isPhone: false,
    chineseCapable: true,
    caseTypeName: 'tag',
    defaultValues: {
      font: 'Serif',
      text: 'A.B',
      color: 'Yellow',
      shadow: 'Mint',
      location: 'Front',
    },
    locations: {
      Front: {
        top: '56%',
        bottom: 'auto',
        left: '0%',
        right: '0',
        transform: '',
        maxLetters: 3,
        displaySize: keyRingsBackDisplaySize,
        previewSize: keyRingsBackPreviewSize,
      },
    },
  },
  'laptop-sleeve': {
    imageName: 'laptop-sleeve',
    fonts: [],
    fontColors: textColorsLuggage,
    colors: ['1', '2'],
    colorNames: colorNamesLuggage,
    isLuggage: true,
    isMe: false,
    isBottle: false,
    isBag: false,
    isPhone: false,
    chineseCapable: true,
    caseTypeName: 'tag',
    defaultValues: {
      font: 'Serif',
      text: 'A.B',
      color: 'Yellow',
      shadow: 'Mint',
      location: 'Back',
    },
    locations: {
      Back: {
        top: '66%',
        bottom: 'auto',
        left: '0%',
        right: '0',
        transform: '',
        maxLetters: 3,
        displaySize: laptopTagBackDisplaySize,
        previewSize: laptopTagBackPreviewSize,
      },
    },
  },
  'heart-tag': {
    imageName: 'heart-tag',
    fonts: [],
    fontColors: textColorsLuggage,
    colors: ['1', '2'],
    colorNames: colorNamesLuggage,
    isLuggage: true,
    isMe: false,
    isBottle: false,
    isBag: false,
    isPhone: false,
    chineseCapable: true,
    caseTypeName: 'tag',
    defaultValues: {
      font: 'Serif',
      text: 'A.B',
      color: 'Yellow',
      shadow: 'Mint',
      location: 'Vertical',
    },
    locations: {
      Vertical: {
        top: '56%',
        bottom: 'auto',
        left: '0%',
        right: '0',
        transform: '',
        maxLetters: 3,
        displaySize: tagBackDisplaySize,
        previewSize: tagBackPreviewSize,
      },
    },
  },
  'flower-tag': {
    imageName: 'flower-tag',
    fonts: [],
    fontColors: textColorsLuggage,
    colors: ['1', '2'],
    colorNames: colorNamesLuggage,
    isLuggage: true,
    isMe: false,
    isBottle: false,
    isBag: false,
    isPhone: false,
    chineseCapable: true,
    caseTypeName: 'tag',
    defaultValues: {
      font: 'Serif',
      text: 'A.B',
      color: 'Yellow',
      shadow: 'Mint',
      location: 'Vertical',
    },
    locations: {
      Vertical: {
        top: '56%',
        bottom: 'auto',
        left: '-1%',
        right: '0',
        transform: '',
        maxLetters: 3,
        displaySize: tagBackDisplaySize,
        previewSize: tagBackPreviewSize,
      },
    },
  },
  'cardholder': {
    imageName: 'cardholder',
    fonts: [],
    fontColors: textColorsLuggage,
    colors: ['1', '2'],
    colorNames: colorNamesLuggage,
    isLuggage: true,
    isMe: false,
    isBottle: false,
    isBag: false,
    isPhone: false,
    chineseCapable: true,
    caseTypeName: 'bag',
    defaultValues: {
      font: 'Serif',
      text: 'A.B',
      color: 'Yellow',
      shadow: 'Mint',
      location: 'Back',
    },
    locations: {
      Back: {
        top: '50%',
        bottom: 'auto',
        left: '0',
        right: '0%',
        transform: 'rotate(0)',
        maxLetters: 3,
        displaySize: passportBackDisplaySize,
        previewSize: passportBackPreviewSize,
      },
    },
  },
  'passport-holder': {
    imageName: 'passport-holder',
    fonts: [],
    fontColors: textColorsLuggage,
    colors: ['1', '2'],
    colorNames: colorNamesLuggage,
    isLuggage: true,
    isMe: false,
    isBottle: false,
    isBag: false,
    isPhone: false,
    chineseCapable: true,
    caseTypeName: 'bag',
    defaultValues: {
      font: 'Serif',
      text: 'A.B',
      color: 'Yellow',
      shadow: 'Mint',
      location: 'Back',
    },
    locations: {
      Back: {
        top: '62%',
        bottom: 'auto',
        left: '0',
        right: '0%',
        transform: 'rotate(0)',
        maxLetters: 3,
        displaySize: passportBackDisplaySize,
        previewSize: passportBackPreviewSize,
      },
    },
  },
  'small-wallet': {
    imageName: 'small-wallet',
    fonts: [],
    fontColors: textColorsLuggage,
    colors: ['1', '2'],
    colorNames: colorNamesLuggage,
    isLuggage: true,
    isMe: false,
    isBottle: false,
    isBag: false,
    isPhone: false,
    chineseCapable: true,
    caseTypeName: 'tag',
    defaultValues: {
      font: 'Serif',
      text: 'A.B',
      color: 'Yellow',
      shadow: 'Mint',
      location: 'Front',
    },
    locations: {
      Front: {
        top: '61%',
        bottom: 'auto',
        left: '0%',
        right: '0',
        transform: '',
        maxLetters: 3,
        displaySize: walletBackDisplaySize,
        previewSize: walletBackPreviewSize,
      },
      Back: {
        top: '61%',
        bottom: 'auto',
        left: '-1%',
        right: '0',
        transform: '',
        maxLetters: 3,
        displaySize: walletBackDisplaySize,
        previewSize: walletBackPreviewSize,
      },
    },
  },
  'large-wallet': {
    imageName: 'large-wallet',
    fonts: [],
    fontColors: textColorsLuggage,
    colors: ['1', '2'],
    colorNames: colorNamesLuggage,
    isLuggage: true,
    isMe: false,
    isBottle: false,
    isBag: false,
    isPhone: false,
    chineseCapable: true,
    caseTypeName: 'tag',
    defaultValues: {
      font: 'Serif',
      text: 'A.B',
      color: 'Yellow',
      shadow: 'Mint',
      location: 'Front',
    },
    locations: {
      Front: {
        top: '70%',
        bottom: 'auto',
        left: '-1%',
        right: '0',
        transform: '',
        maxLetters: 3,
        displaySize: walletBackDisplaySize,
        previewSize: walletBackPreviewSize,
      },
      Back: {
        top: '70%',
        bottom: 'auto',
        left: '-1%',
        right: '0',
        transform: '',
        maxLetters: 3,
        displaySize: walletBackDisplaySize,
        previewSize: walletBackPreviewSize,
      },
    },
  },
  'compact-wallet': {
    imageName: 'compact-wallet',
    fonts: [],
    fontColors: textColorsLuggage,
    colors: ['1', '2'],
    colorNames: colorNamesLuggage,
    isLuggage: true,
    isMe: false,
    isBottle: false,
    isBag: false,
    isPhone: false,
    chineseCapable: true,
    caseTypeName: 'tag',
    defaultValues: {
      font: 'Serif',
      text: 'A.B',
      color: 'Yellow',
      shadow: 'Mint',
      location: 'Front',
    },
    locations: {
      Front: {
        top: '62%',
        bottom: 'auto',
        left: '-1%',
        right: '0',
        transform: '',
        maxLetters: 3,
        displaySize: walletBackDisplaySize,
        previewSize: walletBackPreviewSize,
      },
      Back: {
        top: '62%',
        bottom: 'auto',
        left: '-1%',
        right: '0',
        transform: '',
        maxLetters: 3,
        displaySize: walletBackDisplaySize,
        previewSize: walletBackPreviewSize,
      },
    },
  },
}

const decorativeColours = {
  'Lilac': ['#C2AECD', '#826A80'],
  'Blue': ['#A0D0D7', '#75A6B3'],
  'Pink': ['#C36C89', '#8B4655'],
  'Yellow': ['#E8DF8E', '#C99B3E'],
  'White': ['#FFFFFF', '#95979E'],
  'Black': ['#000000', '#63727E']
}

const embosColours = {
  'Gold': '#FFD700',
  'Silver': '#c0c0c0',
  'Blind': '#212a2c',
}

const printFonts = [
  'heritage',
  'bold-new',
  'bubble',
  'retro-serif',
  'graphic',
  'sans-serif',
  'Retro',
  'Cursive',
  'Block',
  'Signature',
  'Serif'
]

const bagFonts = [
  'all-serif',
  'all-sans',
]

const embosFonts = [
  'sans-serif-embos',
  'serif-embos'
]

export const returnMatchingPersonalisation = (caseSize) => {
  try {
    if (localStorage.previous_personalisation !== undefined) {
      let allPersonalisation = JSON.parse(localStorage.previous_personalisation)

      const selectedCaseData = CaseData[caseSize]
      const isPrint = selectedCaseData?.isLuggage || selectedCaseData?.isMe
      const isEmbos = selectedCaseData?.isEmbos
      let fonts =
        isEmbos ?
          embosFonts : (isPrint ? printFonts : bagFonts)

      let colours = isEmbos ? embosColours : Object.keys(isPrint ? textColorsLuggage : textColorsBags)
      let locations = CaseData[caseSize] ?  Object.keys(CaseData[caseSize]?.locations) : []

      let matchPersonalisation = []
      for (let pers of allPersonalisation) {
        if (
          locations.includes(pers.position) &&
          fonts.includes(pers.font) &&
          colours.includes(pers.color) &&
          (pers.shadow !== undefined ? colours.includes(pers.shadow) : true)
        ) {
          matchPersonalisation.push(pers)
        }
      }

      return matchPersonalisation.slice(0, 10);
    }
  } catch (e) {
    console.log(e)
  }
  return []
}

const PersonalisationModal = ({
  handleClose,
  handleSave,
  initialValues,
  selectedCase,
  selectedCaseColor,
  timeframe
}) => {
  const [caseSize, setCaseSize] = useState(selectedCase)
  const [previousPers, setPreviousPers] = useState(returnMatchingPersonalisation(selectedCase))
  const productData = CaseData[caseSize]

  const initialiseValues = (param) =>
    initialValues[param]
      ? initialValues[param]
      : productData?.defaultValues[param]

  const [changed, setChanged] = useState(
    initialValues && initialValues['text'] !== undefined
  )
  const [font, setFont] = useState(initialiseValues('font'))
  const [color, setColor] = useState(initialiseValues('color'))
  const [shadow, setShadow] = useState(initialiseValues('shadow'))
  const [location, setLocation] = useState(initialiseValues('location'))
  const [text, setText] = useState(initialiseValues('text'))
  const [textFull, setTextFull] = useState(
    initialValues && initialValues['text'] !== undefined
      ? initialiseValues('text')
      : ''
  )
  const [accepted, setAccepted] = useState(
    initialValues && initialValues['text'] !== undefined
  )
  const [language, setLanguage] = useState('english')
  const [colorType, setColorType] = useState(0)
  const [completionAmount, setCompletionAmount] = useState(-1)
  const [selectedStep, setSelectedStep] = useState(undefined)
  const [displayStep, setDisplayStep] = useState(undefined)
  const [completedSteps, setCompletedSteps] = useState({
    0: false,
    1: false,
    2: false,
    3: false,
  })
  let textColors = productData.fontColors
  const colorNames = productData.colorNames
  const locationData = productData.locations[location]

  const maxLetters = Math.min(
    locationData.maxLetters,
    fontData[font] ? fontData[font]['letterLimit'] : 7
  )
  const uppercase = fontData[font] ? fontData[font].uppercase : false

  const [currentFontData, setCurrentFontData] = useState(fontData)

  useEffect(() => {
    setColorType(0)
    if (changed === true) {
      handleTextChange({ currentTarget: { value: textFull } })
    }
    const newFontData = {}
    Object.keys(fontData).map((key) => {
      const value = fontData[key]
      newFontData[key] = {
        ...value,
        letterLimit: Math.min(value.letterLimit, locationData.maxLetters),
      }
    })
    setCurrentFontData(newFontData)
  }, [font, location])

  const saveMiddleware = (labelData) => {
    try {
      if (localStorage.previous_personalisation !== undefined) {
        let previousPersonalisation = JSON.parse(localStorage.previous_personalisation)
        if (!objectExists(previousPersonalisation, labelData)) {
          previousPersonalisation = [labelData, ...previousPersonalisation].slice(0, 25);
          localStorage.setItem('previous_personalisation', JSON.stringify(previousPersonalisation))
        }
      } else {
        let previousPersonalisation = [labelData]
        localStorage.setItem('previous_personalisation', JSON.stringify(previousPersonalisation))
      }
    } catch (e) {
      console.log(e)
    }
    handleSave(labelData)
  }

  const handleAddToCart = async () => {
    if (changed) {
      text === ''
        ? saveMiddleware(initialValues)
        : saveMiddleware({
          text: textTransform(text, font),
          font: font.toLowerCase(),
          position: location,
          color,
          ...(['block', 'college', 'heritage'].includes(font.toLowerCase()) ? { shadow } : {}),
        })
    }
  }
  const handleExit = () => {
    handleClose()
  }

  const handleChange = (newV) => {
    setLanguage(newV)
    if (newV === 'chinese') {
      setFont('ZH-BLOCK')
      handleTextChange({ currentTarget: { value: '七月' } })
    } else {
      setFont('heritage')
      handleTextChange({ currentTarget: { value: 'JLY' } })
    }
  }

  useEffect(() => {
    const containsChinese = ChineseReg.test(text)
    if (language !== 'chinese' && containsChinese && productData.isLuggage) {
      setFont('ZH-BLOCK')
      setLanguage('chinese')
    }
  }, [])

  const handleTextChange = (e) => {
    if (changed === false) {
      setChanged(true)
    }
    const newValue = e.currentTarget.value

    const containsChinese = ChineseReg.test(newValue)
    const containsEmoji = Emojireg.test(newValue)
    if (
      !(productData.isLuggage || productData.isMe) &&
      (containsChinese || containsEmoji)
    )
      return

    if (language !== 'chinese' && containsChinese && productData.isLuggage) {
      setFont('ZH-BLOCK')
      setLanguage('chinese')
    }
    if (language === 'chinese' || containsChinese) {
      let EngChars = `${newValue}`
      splitter(newValue)
        .map(
          (val) =>
            (EngChars = EngChars.replace(Emojireg, '').replace(ChineseReg, ''))
        )
      const ChinChars = `${newValue}`.replace(EngChars, '').replace(/\s+/g, '')
      setText((prev) =>
        splitter(ChinChars).length > maxLetters
          ? splitter(ChinChars).slice(0, maxLetters).join('')
          : ChinChars
      )
      setTextFull((prev) =>
        splitter(ChinChars).length > maxLetters
          ? splitter(ChinChars).slice(0, maxLetters).join('')
          : newValue
      )
    } else {
      // if (
      //   /^[A-zÀ-ÿ\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff]]+$/.test(newValue) ||
      //   splitter(newValue).length === 0 ||
      //   /(\p{Script=Hani})+/gu.test(newValue)
      // )

      setTextFull((prev) => newValue)
      setText((prev) =>
        splitter(newValue)
          .slice(0, maxLetters)
          .map((val) => (uppercase === true ? val.toUpperCase() : val))
          .join('')
      )
    }
  }

  const handleDone = () => {
    setCompletionAmount((prev) =>
      displayStep > completionAmount ? displayStep : prev
    )
    setCompletedSteps((prev) => {
      let newObj = { ...prev }
      newObj[`${displayStep}`] = true
      return newObj
    })
    setSelectedStep(undefined)
    setDisplayStep(undefined)
  }

  if (font === 'decorative') {
    textColors = decorativeColours
  }

  if (font.includes('embos')) {
    textColors = embosColours
  }

  return (
    <Div100vh>
      <ContentContanier>
        <PreviewPanel
          imageName={productData.imageName}
          selectedStep={selectedStep}
          isMe={productData.isMe}
          isBag={productData.isBag}
          isPhone={productData.isPhone}
          handleExit={handleExit}
          accepted={accepted}
          caseSize={caseSize}
          text={text}
          font={font}
          color={color}
          shadow={shadow}
          location={location}
          textColors={textColors}
          textTransform={textTransform}
          selectedCaseColor={selectedCaseColor}
          title=""
          locationData={locationData}
          maxLetters={maxLetters}
          isBottle={productData.isBottle}
          timeframe={timeframe}
        />
        <SelectorPanel
          previousPersonalisation={previousPers}
          caseTypeName={productData.caseTypeName}
          imageName={productData.imageName}
          changed={changed}
          maxLetters={maxLetters}
          title={''}
          selectedStep={selectedStep}
          isLuggage={productData.isLuggage}
          completionAmount={completionAmount}
          accepted={accepted}
          completedSteps={completedSteps}
          setSelectedStep={setSelectedStep}
          displayStep={displayStep}
          setDisplayStep={setDisplayStep}
          isPhone={productData.isPhone}
          isMe={productData.isMe}
          isBottle={productData.isBottle}
          isEmbos={productData.isEmbos}
          isTrunk={productData.isTrunk}
          setFont={setFont}
          font={font}
          text={text}
          textFull={textFull}
          handleTextChange={handleTextChange}
          value={language}
          handleChange={handleChange}
          textColors={textColors}
          setColorType={setColorType}
          setColor={setColor}
          color={color}
          shadow={shadow}
          location={location}
          setLocation={setLocation}
          setAccepted={setAccepted}
          setShadow={setShadow}
          colorType={colorType}
          colorNames={colorNames}
          caseSize={caseSize}
          handleAddToCart={handleAddToCart}
          handleDone={handleDone}
          selectedCaseColor={selectedCaseColor}
          currentFontData={currentFontData}
          locations={Object.keys(productData.locations)}
        />
      </ContentContanier>
    </Div100vh>
  )
}

export default PersonalisationModal
